/**
 * @generated SignedSource<<394defaa2b1ead7a52d5ad77e37e778d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksContentHelperContent$data = {
  readonly flag: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersSanitizeArticleTypeEntityIdsArticle" | "helpersSanitizeAuthorTypesArticle" | "helpersSanitizedAuthorEntityIdsArticle">;
  readonly " $fragmentType": "hooksContentHelperContent";
};
export type hooksContentHelperContent$key = {
  readonly " $data"?: hooksContentHelperContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksContentHelperContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksContentHelperContent"
};

(node as any).hash = "0d5848ec79408908bde8ab27c4b699e2";

export default node;
