/**
 * @generated SignedSource<<cdb0ab84159689b2b5bb35fb97cc3dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksContentHeadlineTagContent$data = {
  readonly __typename: string;
  readonly flag: string | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly types?: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly description: {
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly entityId: string;
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle" | "hooksContentHelperContent">;
  readonly " $fragmentType": "hooksContentHeadlineTagContent";
};
export type hooksContentHeadlineTagContent$key = {
  readonly " $data"?: hooksContentHeadlineTagContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksContentHeadlineTagContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksContentHeadlineTagContent"
};

(node as any).hash = "786a7f147dce17ba831fc462c4b2ba11";

export default node;
