export const FlagMapping = {
  AEROSPACE: "Aerospace",
  AI: "AI",
  ANALYSIS: "Analysis",
  BIOMEDICINE: "Biomedicine",
  BREAKING: "Breaking",
  CONTEST: "Contest",
  DEVELOPING: "Developing",
  EVS: "EVs",
  EXCLUSIVE: "Exclusive",
  GLOBAL_IMPACT: "Global Impact",
  INTERACTIVE: "Interactive",
  LIVE: "Live",
  LIVE_QA: "Live Q&A",
  NEW: "New",
  ON_THIS_DAY: "On This Day",
  OPEN_QUESTIONS: "Open Questions",
  POLITICO: "Politico",
  QUIZ: "Quiz",
  REVIEW: "Review",
  ROBOTICS: "Robotics",
  SEMICONDUCTORS: "Semiconductors",
  UPDATE: "Update",
  VIDEO: "Video",
};
